/* CommonTableComponent.css */

/* Apply styles to the table */
table {
  border-collapse: collapse;
  width: 100%;
}

/* Apply styles to table headers (optional) */
thead th {
  border: 2px solid #ea4142;
  text-align: left;
  padding: 8px;
}

/* Apply styles to table cells */
td,
th {
  border: 2px solid #ea4142;
  text-align: left;
  padding: 8px;
  font-size: 18px;
  /* font-weight: 600; */
}

.common-table-outer-container {
  margin-bottom: 2%;
}

@media screen and (max-width: 769px) {
  td,
  th {
    font-size: 16px;
  }
}

@media screen and (max-width: 469px) {
  td,
  th {
    font-size: 12px;
  }
}
