.oops-outer-container {
  margin: 3% 0;
}

.oops-inner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  text-align: center;
}

.oops-heading {
  color: #d42d2d;
  font-size: 96px;
  margin: 0;
}

.page-not-found-msg {
  font-size: 65px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 5px;
}

.oops-sorry-msg {
  font-size: 20px;
  margin-top: 0;
  width: 525px;
}

.homepage-btn button {
  border-radius: 10px;
  background: #d42d2d;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  outline: none;
  border: none;
  font-size: 30px;
  font-weight: 700;
  padding: 8px 18px;
  cursor: pointer;
}

@media screen and (max-width: 769px) {
  .oops-image-container img {
    width: 640px;
  }

  .oops-heading {
    font-size: 70px;
  }

  .page-not-found-msg {
    font-size: 55px;
  }
}

@media screen and (max-width: 580px) {
  .oops-image-container img {
    width: 350px;
  }

  .oops-heading {
    font-size: 55px;
  }

  .page-not-found-msg {
    font-size: 35px;
  }

  .oops-sorry-msg {
    width: 340px;
    font-size: 12px;
  }

  .homepage-btn button {
    font-size: 20px;
  }
}
