.header-outer-container {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}

.header-red-container {
  background-color: #000;
  color: #fff;
  font-size: 14px;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}

.header-first-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
}

.header-first-right-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  cursor: pointer;
}

.official-contact {
  display: flex;
  align-items: center;
  gap: 5px;
}

.official-contact-info {
  font-size: 14px;
  color: #fff;
}

.header-second-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  background-color: #fff;
}

.header-second-right-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.header-second-right-container p {
  font-size: 16px;
  /* font-weight: 600; */
  text-align: left;
}

.header-dropdown-container {
  display: flex;
  align-items: center;
}

.header-dropdown:hover .header-dropdown-content {
  display: block;
}

.header-dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  /* Add padding for spacing inside the dropdown content */
  max-height: 200px;
  /* Increase the max-height as per your requirement */
  overflow-y: auto;
  border-radius: 10px;
  z-index: 99;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d42d2d;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d42d2d;
}

/* Dropdown styles */
.dropdown {
  position: relative; /* Needed for absolute positioning of dropdown-menu */
}

.dropdown-menu {
  display: none; /* Hide the dropdown menu by default */
  position: absolute;
  top: 100%; /* Position the dropdown below the parent item */
  left: 0;
  background-color: #fff; /* Customize the background color */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Customize the box shadow */
  z-index: 1; /* Ensure the dropdown appears above other elements */
  padding: 10px;
}

/* Show the dropdown menu when hovering over a navigation item */
.nav-item:hover .dropdown-menu {
  display: block;
}

.nav-link {
  display: flex;
  align-items: center;
  font-size: 16px;
  /* font-weight: 600; */
  color: #000;
}

.specific-height-class {
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto; /* Add scrollbar if content exceeds max-height */
}

.dropdown-item p {
  /* Add your styles for a dropdown item here */
  font-size: 16px;
  /* font-weight: 600; */
  text-align: left;
  width: 200px;
  color: #000;
}

.sub-data-dropdown {
  position: absolute;
  top: 0;
  left: 100%; /* Position to the right of the parent */
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1000;
  padding: 10px;
  /* min-width: 200px; */
}

.nav-item:hover .dropdown-menu .sub-data-dropdown {
  display: block;
}

.sub-data-item {
  padding: 5px 0;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  transition: color 0.2s ease-in-out;
}

.sub-data-item:hover {
  color: #007bff; /* Change the color on hover */
}

/* Add styles for the ArrowForwardIosIcon */
.nav-link .MuiSvgIcon-root {
  vertical-align: middle;
  font-size: 16px;
  margin-left: 5px; /* Adjust spacing as needed */
}

.dropdown-item div .MuiSvgIcon-root {
  font-size: 14px;
  color: #000;
}

.official-pay-now-btn button {
  background-color: #d42d2d;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  border-radius: 5px;
  border: none;
  padding: 7px 5px;
}

@media screen and (max-width: 768px) {
  .header-first-container {
    display: none;
  }

  .mobile-hamburger-container {
    position: sticky;
    top: 0;
    z-index: 99;
    display: flex;
    flex-direction: row-reverse;
    background-color: #fff;
  }
}
