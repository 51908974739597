.abroad-thank-inner-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.abroad-thank-msg {
  color: #ea4142;
  font-size: 32px !important;
  font-weight: 700;
  margin-top: 7% !important;
}

.abroad-thank-submsg {
  font-size: 20px !important;
  font-weight: 700;
}

.abroad-trip-verify .MuiSvgIcon-root {
  fill: #2eb758;
  font-size: 80px;
}
