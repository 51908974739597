.banner-canada-pnp {
  position: relative;
  margin-bottom: 3%;
}

.banner-canada-pnp img {
    width: 1525px;
  }

.overlay-text-pnp {
  font-size: 50px;
  position: absolute;
  top: 20%;
  left: 0;
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  text-align: left;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 2; /* Ensure the text is above the image */
}

@media screen and (max-width: 769px) {
  .overlay-text-pnp {
    font-size: 16px;
  }
}
