.canada-express-outer-container {
  margin-top: 2%;
}

.canada-express-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 2rem;
}

.canada-flex-left-container {
  flex: 50% 1;
  overflow: auto;
}

.canada-flex-right-container {
  flex: 20% 1;
  position: sticky;
  top: 110px;
}

.fixed-container {
  /* position: fixed;
  right: 0;
  top: 0;
  z-index: 1; */
  /* flex: 20% 1; */
}

.canada-flex-right-container .abroad-form-outer-containet {
  width: unset;
}

.canada-express-points-container {
  padding-top: 2rem;
}

.canada-express-points-container h2 {
  font-size: 24px;
  text-align: left;
  margin: 0;
}

.canada-express-points-container p ul li {
  padding-bottom: 25px;
}

.canada-express-points-container p {
  text-align: justify;
  font-size: 18px;
}

.get-free-assessment-btn button {
  border-radius: 10px;
  background-color: #ea4142;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  border: none;
  padding: 5px 8px;
  cursor: pointer;
}

.canada-express-main-container {
  margin-top: 2%;
}

.occupation-demand-list-outer-container {
  background-color: #e0e0e0;
}

.occupation-demand-list-inner-container {
  padding-top: 1rem;
  margin-top: 2%;
  padding-bottom: 1rem;
}

.occupation-demand {
  color: #0085ff;
  font-size: 26px;
  font-weight: 600;
  text-align: left;
}

.occupation-text {
  font-size: 24px;
  text-align: left;
  font-weight: 600;
}

.pointer-process p ul li {
  list-style: none;
}

.pointer-process-canada p ul li {
  list-style: url("https://achintyaconsultant.s3.ap-south-1.amazonaws.com/check-mark+(1)+5.png");
}

.pointer-process p ul li,
.pointer-process-canada p ul li {
  padding-bottom: 24px;
}

.pointer-process-canada {
  text-align: justify;
}

.pointer-process-canada p,
.canada-ita-inner-container p {
  font-size: 18px;
  text-align: justify;
}

.canada-ita-inner-container h2 {
  text-align: left;
  font-size: 24px;
  margin: 4% 0 2% 0;
}

.canada-criteria-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.canada-citeria {
  margin-top: 2%;
}

.canada-process-image {
  padding-top: 2rem;
}

@media screen and (max-width: 768px) {
  .canada-express-flex-container {
    flex-direction: column;
  }

  .canada-express-points-container h2 {
    font-size: 20px;
  }

  .canada-express-points-container p {
    font-size: 16px;
  }

  .canada-process-image img {
    width: 85%;
  }

  .criteria-left-container img {
    width: 100%;
  }

  .canada-criteria-flex {
    flex-direction: column;
  }

  .canada-express-flex-container {
    align-items: center;
  }
}
