.pr-process-inner-container {
  padding: 0 5rem;
}

.pr-process-heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 2px;
}

.pr-process-sub-heading {
  font-size: 20px;
  margin: 0;
  margin-bottom: 5px;
}

.pr-process-pointer {
  background-color: #a6191c;
  border-radius: 100px 5px 5px 100px;
}

.process-container {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
  padding: 10px 0;
}

.pr-step-container {
  background-color: #fff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 1%;
  font-size: 22px;
  font-weight: 600;
}

.pr-steps-description {
  text-align: left;
  color: #fff;
}

.pr-steps-heading {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  margin-top: 8px;
}

.pr-steps  {
  margin: 0;
  font-size: 16px;
}

@media screen and (max-width: 769px) {
  .pr-process-heading {
    font-size: 18px;
  }

  .pr-process-sub-heading {
    font-size: 16px;
  }

  .pr-process-inner-container {
    padding: 0;
  }

  .pr-step-container {
    width: 200px;
    height: 140px;
  }
}

/* @media screen and (max-width: 769px) {
  .pr-step-container {
    width: 200px;
    height: 100px;
  }
} */
