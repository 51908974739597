.canada-pnp-process-inner-container h2,
.canada-pnp-eligiblity-container h2 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.canada-pnp-process-inner-container p {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
}

.canada-pnp-process-img img {
  /* width: 800px; */
}

.canada-pnp-eligiblity-container {
  background-color: #f2f2f2;
  padding: 2rem 0;
}

.canada-pnp-eligiblity-container h2 {
  color: #d42d2d;
}

.canada-pnp-eligibility-inner-container {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.canada-pnp-eligibility-left-container p,
.processing-time-inner-container ol li {
  font-size: 18px;
  text-align: justify;
}

.processing-time-inner-container ol li {
  padding-bottom: 24px;
}

.canada-pnp-paper-container {
  background-color: #f2f2f2;
  padding: 2rem 0;
}

.canada-pnp-paper-inner-container h2,
.canada-pnp-express h2,
.processing-time-inner-container h2 {
  color: #d42d2d;
  font-size: 24px;
}

.canada-pnp-paper-inner-container p,
.canada-pnp-express-left-container p,
.canada-pnp-express-left-container ol li,
.processing-time-inner-container ul li,
.processing-time-inner-container p {
  font-size: 18px;
  text-align: justify;
}

.canada-pnp-express-left-container ol li,
.pnpn-list ul li {
  padding-bottom: 24px;
  text-align: justify;
  font-size: 18px;
}

.processing-time-outer-container {
  padding: 10px 0;
}

.processing-time-inner-container ul li {
  padding-bottom: 10px;
}

.canada-pnp-express-inner-container {
  display: flex;
  gap: 2rem;
  align-items: center;
}

@media screen and (max-width: 769px) {
  .canada-pnp-process-img img {
    width: 100%;
  }

  .canada-pnp-process-inner-container p {
    font-size: 16px;
  }

  .canada-pnp-eligibility-inner-container,
  .canada-pnp-express-inner-container {
    flex-direction: column;
  }

  .canada-pnp-eligibility-right-container img,
  .canada-pnp-express-right-container img {
    width: 100%;
  }

  .canada-pnp-eligiblity-container {
    margin-top: 10%;
  }

  .canada-pnp-paper-inner-container h2,
  .canada-pnp-express h2,
  .processing-time-inner-container h2 {
    font-size: 18px;
  }

  .canada-pnp-paper-inner-container p,
  .processing-time-inner-container ul li,
  .processing-time-inner-container p,
  .canada-pnp-express-left-container ol li {
    font-size: 16px;
  }
}
