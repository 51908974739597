.about-achintya-heading {
  font-size: 24px;
  font-weight: 700;
}

.about-achintya-description{
  display: flex;
  align-items: start;
  gap: 2rem;
}

.about-achintya-description p {
  text-align: justify;
  font-size: 18px;
  flex: 50%;
  margin-top: 0;
}

.monument-image img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .about-achintya-heading {
    font-size: 20px;
  }

  .about-achintya-description{
    flex-direction: column;
  }

  .about-achintya-description p {
    font-size: 16px;
  }
}
