.service-outer-container {
  background-color: #ececec;
  padding: 1rem;
}

.service-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.services-haeding {
  font-size: 24px;
  font-weight: 700;
}

.service-right-container {
  overflow-y: auto;
  max-height: 340px;
  padding-left: 2rem;
}

.type-service-name {
  text-align: left;
  font-size: 18px;
  font-weight: 700;
}

.type-service-description {
  text-align: left;
  font-size: 16px;
}

.service-vertical-line {
  width: 5px;
  height: 380px; /* Adjust the height as needed */
  background-color: #d42d2d;
}

.why-enquiry-btn {
  border-radius: 10px;
  background: #d42d2d;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  outline: none;
  color: #fff;
  font-size: 20px;
  padding: 10px 120px;
  border: none;
  cursor: pointer;
}

.service-left-container img {
  width: 350px;
}

@media screen and (max-width: 769px) {
  .service-inner-container {
    flex-direction: column;
  }

  .service-vertical-line {
    display: none;
  }

  .service-right-container {
    padding: 0;
  }

  .service-left-container img {
    width: 100%;
  }
}
