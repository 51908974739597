.terms-heading-container h2 {
  font-size: 42px;
  font-weight: 700;
}

.terms-content-container {
  text-align: left;
}

.terms-content1 p {
  margin: 0;
  margin-bottom: 5px;
  font-size: 16px;
}

.terms-sub-sub-haeding p {
  font-size: 16px;
  font-weight: 600;
}

.margin-bottom-change p {
  margin-bottom: 1px;
}

.terms-content1 p a{
    color: blue;
}
