.locate-noc-outer-container {
  background-color: #f4f4f4;
  margin: 3% 0;
}

.locate_noc_heading {
  color: #f00;
  font-size: 24px;
  font-weight: 700;
}

.locate_noc_content {
  text-align: justify;
  font-size: 18px;
}

.locate-noc-inner-container {
  padding: 2rem 0;
}

.loc-flex-container {
  display: flex;
  align-items: start;
  justify-content: space-around;
}

.loc-left-flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;
}

@media screen and (max-width: 769px) {
  .locate_noc_content {
    font-size: 16px;
  }

  .locate_noc_heading {
    font-size: 20px;
  }

  .loc-flex-container {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }

  .loc-left-flex-container img {
    display: none;
  }
}
