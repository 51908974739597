.privacy-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
}

.privacy-heading-container h2 {
  color: #fff;
  font-size: 42px;
  font-weight: 700;
}

.privacy-heading-container img {
  width: 510px;
}

.privacy-outer-heading-container {
  background-color: #d42d2d;
}

.privacy-image {
  width: 510px;
}

.privay-content-container {
  text-align: left;
}

.privacy-sub-heading {
  font-size: 20px;
  font-weight: 600;
}

.privacy-content1 p {
  margin: 0;
  margin-bottom: 5px;
  font-size: 16px;
}

.privacy-sub-sub-haeding p {
  font-size: 16px;
  font-weight: 600;
}

.margin-bottom-change p {
  margin-bottom: 1px;
}

.privacy-des-flex {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 769px) {
  .privacy-sub-heading {
    font-size: 16px;
  }

  .privacy-contact-info {
    font-size: 12px !important;
  }

  .privacy-heading-container {
    flex-direction: column;
    gap: 15rem;
  }

  .privacy-heading-container img {
    width: 100%;
  }
}
