.enquiry-outer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: grid;
  place-content: center;
}

.formWrapper {
  background-color: #fefefe;
  width: min(80vw, 1000px);
  height: min(100vh, 700px);
  border-radius: 20px;
  -webkit-font-smoothing: antialiased;
  backface-visibility: hidden;
}

.formWrapper_header {
  background-color: #d42d2d;
  border-radius: 20px 20px 0px 0px;
  text-align: center;
  position: relative;
  /* display: flex;
    align-items: center; */
}

.form-heading {
  margin: 0;
  font-size: clamp(14px, 4vw, 24px);
  font-weight: 700;
  color: #fff;
  padding: 1rem;
}

.formWrapper .close-icon .MuiSvgIcon-root {
  position: absolute;
  right: 0;
  top: 35%;
  padding-right: 2rem;
  cursor: pointer;
  width: auto;
  color: #fff;
  font-size: clamp(14px, 4vw, 24px);
}

.formWrapper_body {
  height: calc(100% - 120px);
  padding: 30px 0;
  padding-top: 20px;
}

.form-body-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: start;
  padding: 15px 0;
  height: 100%;
}

.sections {
  flex: 0.45;
  height: 100%;
  display: flex;
  justify-self: center;
  align-items: center;
}

.form-divider-line {
  border: 1px dotted #d42d2d;
  opacity: 1;
  height: 100%;
}

.form-left-section img {
  width: 100%;
}

@media (min-width: 769px) and (max-width: 1439px) {
  .formWrapper {
    zoom: 80%;
  }
}

@media screen and (max-width: 1100px) {
  .sections {
    flex: 0.9;
    width: 90%;
  }

  .formWrapper {
    height: min(100vh, 568px);
  }
}

@media screen and (max-width: 769px) {
  .form-left-section,
  .form-divider-line {
    display: none;
  }
}
