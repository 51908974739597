.whats-outer-container {
    position: fixed;
    bottom: 15px;
    right: 2px;
    cursor: pointer;
    z-index: 99;
}

.whatsapp-container img{
    width: 40px;
}