.apply-outer-container button {
  background-color: #d42d2d;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  border-radius: 5px;
  border: none;
  padding: 7px 10px;
  cursor: pointer;
}

.apply-outer-container{
    display: flex;
}

@media screen and (max-width: 769px) {
  .apply-outer-container :no-button {
    font-size: 18px;
  }

  .apply-outer-container{
    justify-content: center;
  }
}
