.canada-page-points-container h2 {
  font-size: 24px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 10px;
  background-color: #2c82c1;
  padding: 9px;
  border-radius: 5px;
  color: #fff;
}

.canada-page-points-container {
  /* padding-top: 1rem; */
}

.canada-page-points-container-subHeading h2 {
  font-size: 24px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 10px;
}

.canada-page-points-container-subHeading p {
  text-align: left;
  font-size: 20px;
}

.canada-page-points-container-subHeading p ul li {
  text-align: left;
  padding-bottom: 25px;
  font-size: 20px;
}

.canada-page-points-container p {
  text-align: justify;
  font-size: 18px;
  margin-top: 4%;
}

.canada-page-points-container p ul li {
  padding-bottom: 24px;
}

.canada-page-outer-container {
  margin-top: 3%;
}

@media screen and (max-width: 769px) {
  .canada-page-points-container-subHeading h2,
  .canada-page-points-container h2 {
    font-size: 18px;
  }

  .canada-page-points-container p,
  .canada-page-points-container-subHeading p ul li,
  .canada-page-points-container-subHeading p {
    font-size: 16px;
  }

  .canada-pnp-image img {
    width: 100%;
  }
}
