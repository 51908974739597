.get-free-assessment-btn button {
  border-radius: 10px;
  background-color: #ea4142;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  border: none;
  padding: 5px 8px;
  cursor: pointer;
}

@media screen and (max-width: 769px) {
  .canada-page-flex-container {
    flex-direction: column;
    align-items: inherit;
    margin-bottom: 2%;
  }
}
