.get-in-touch-outer-container {
  border-radius: 30px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  background: #fff;
  box-shadow: 0px 8px 10px 0px rgba(222, 110, 75, 0.25);
  margin: 7%;
}

.get-in-touch-inner-container {
  display: flex;
  align-items: center;
}

.get-in-touch-left-container {
  width: 100%;
}

.get-in-touch-heading {
  background-color: #d42d2d;
  border-radius: 30px 0 0 0;
}

.get-in-touch-heading p {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  padding: 1rem;
}

.get-in-touch-form-container {
  background: #f1f1f1;
  padding: 2rem;
  border-radius: 0 0 0 30px;
}

.get-in-touch-right-container {
  background-color: #fff;
  width: 100%;
  padding: 2rem;
}

.achintiya-form-logo {
  text-align: left;
}

.get-map-container {
  border-radius: 10px;
  text-align: center;
  margin-bottom: 3%;
}

.conatct-loc-container {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 2% 0;
}

.location-para {
  margin: 0;
  font-size: 14px;
  text-align: left;
}

.get-in-touch-loaction-container {
  width: 440px;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .get-in-touch-inner-container {
    flex-direction: column;
  }

  .get-in-touch-right-container {
    background-color: inherit;
  }

  .achintiya-form-logo{
    display: none;
  }
}

@media screen and (max-width: 769px) {
  .get-in-touch-heading {
    border-radius: 30px 30px 0 0;
  }

  .get-in-touch-loaction-container{
    width: 300px;
  }
}
