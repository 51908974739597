.ielts-page-banner-outer-container {
  background-color: #213966;
}

.ielts-banner-heading-container h2 {
  color: #fff;
  font-size: 50px;
  text-align: left;
}

.vertical-text-ielts {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  white-space: nowrap;
  transform: rotate(180deg);
  transform-origin: left bottom;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  float: left;
  top: 345px;
}

.ielts-banner-inner-container {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.why-ielts-container {
  background-color: #f4f4f4;
  margin-top: 4%;
}

.why-choose-ielts-heading {
  font-size: 24px;
  font-weight: 700;
}

.why-ielts-inner-container {
  padding: 2rem 0;
}

.ielts-program-inner-container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 2rem;
}

@media screen and (max-width: 769px) {
  .ielts-banner-inner-container {
    flex-direction: column;
  }
  .ielts-banner-inner-container {
    align-items: center;
  }

  .ielts-program-inner-container {
    flex-direction: column;
  }

  .ielts-program-right-container img {
    width: 100%;
  }

  .why-choose-ielts-heading {
    font-size: 18px;
  }

  .ielts-banner-img img {
    width: 100%;
  }

  .ielts-banner-heading-container h2 {
    font-size: 24px;
    text-align: center;
  }

  .vertical-text-ielts {
    top: 180px;
  }
}
