.chart-banner-img img {
  width: 540px;
}

.vertical-text-crs-chart {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  white-space: nowrap;
  transform: rotate(180deg);
  transform-origin: left bottom;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  float: left;
  top: 220px;
}

.custom-table tbody {
  border-top: none;
}

.table-td-width {
  width: 420px;
}

.chart-image {
  padding-top: 2rem;
}

.total-express-points-container p,
.total-express-points-container ul li {
  font-size: 18px;
  text-align: left;
}

.crs-spouse-heading {
  font-size: 22px;
  text-align: center;
  width: 290px;
}

.crs-chart-grey-container {
  background-color: #f4f2f2;
  padding: 2rem 0;
}

.crs-chart-grey-flex-contaienr,
.crs-chart-flex-contaienr {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 2rem;
}

.crs-chart-flex-contaienr {
  justify-content: space-evenly;
}

.grey-text {
  font-weight: 600;
  font-size: 22px;
  text-align: left;
}
span {
  font-weight: 100;
}

.crs-table-color {
  background-color: #fff;
}

.crs-chart-total-points-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.crs-c-heading {
  font-weight: 600;
  font-size: 22px;
}

@media screen and (max-width: 769px) {
  .crs-chart-banner-img img {
    width: 100%;
  }

  .ielts-banner-inner-container {
    gap: 2rem;
  }

  .vertical-text-crs-chart {
    top: 175px;
  }

  .chart-image img {
    width: 100%;
  }

  .total-express-points-container p,
  .total-express-points-containerul li {
    font-size: 16px;
  }
  .crs-spouse-heading,
  .grey-text {
    font-size: 16px;
  }

  .crs-chart-grey-flex-contaienr,
  .crs-chart-flex-contaienr,
  .crs-chart-total-points-container {
    flex-direction: column;
  }

  .crs-c-heading {
    font-size: 18px;
  }

  .crs-canada-img-container img,
  .crs-img-c img {
    width: 100%;
  }
  th {
    width: 200px;
  }
}
