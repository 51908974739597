.fedral-worker-banner-outer-container {
  background-color: #213996;
}

.fedral-worker-banner-inner-container {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
}

.linear-back-grad {
  background-image: linear-gradient(#213966, #0d1f41, #213966);
}
