.tourist-visa-banner-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tourist-visa-banner-heading {
  font-size: 50px;
  color: #d42d2d;
  font-weight: 700;
  text-align: left;
}

@media screen and (max-width: 769px) {
  .tourist-visa-banner-inner-container {
    flex-direction: column;
  }
  .tourist-visa-banner-heading {
    text-align: center;
    font-size: 22px;
  }

  .tourist-visa-banner-inner-container img {
    width: 100%;
  }
}
