.abroad-form-outer-containet {
  background: #f1f1f1;
  width: 100%;
  border-radius: 5px;
  flex: 20%;
  padding: 2rem;
}

.abroad-form-heading {
  color: #d42d2d;
  font-size: 24px;
  font-weight: 700;
  margin-top: 0;
  text-align: left;
}

.abroad-input-field {
  font-size: 14px;
}

.input-field-between-space {
  margin-top: 2rem;
}

.upload-file-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
}

.upload-file-name {
  margin: 0;
}

.takeoff-btn {
  border-radius: 100px;
  background: #ea4142;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  border: none;
  padding: 6px 20px;
  margin-top: 6%;
  cursor: pointer;
}

@media screen and (max-width: 769px) {
  .abroad-form-outer-containet {
    width: auto;
  }

  .abroad-form-heading {
    font-size: 20px;
  }

  .takeoff-btn {
    font-size: 16px;
  }
}
