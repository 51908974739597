.why-achintya-heading {
  font-size: 24px;
}

.why-subHeading {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  display: flex;
}

.why-description {
  font-size: 16px;
  font-weight: 600;
  text-align: justify;
}

.MuiTimelineDot-root,
.MuiTimelineConnector-root {
  background-color: #d42d2d !important;
}

.why-choose-mobile {
  display: none;
}
.monument-image{
  width: 100%;
}

@media screen and (max-width: 769px) {
  .why-subHeading {
    font-size: 16px;
  }

  .why-description {
    font-size: 14px;
  }

  .why-achintya-heading {
    font-size: 16px;
  }

  .why-choose-desktop {
    display: none;
  }

  .why-choose-mobile {
    display: block;
  }

  .why-choose-mobile .css-ha3bif-MuiTimelineItem-root:before {
    content: none;
  }
}
