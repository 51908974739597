.whyPr-outer-country {
  background: #f4f4f4;
}

.whyPr-inner-container {
  padding: 2rem 0;
}

.whyPr-heading {
  font-size: 26px;
  font-weight: 600;
}

.whyPr-description {
  text-align: left;
  font-size: 18px;
}

@media screen and (max-width: 769px) {
  .whyPr-heading {
    font-size: 22px;
  }

.whyPr-description {
    font-size: 16px;
}
}
