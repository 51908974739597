.pr-documents-outer-container {
  background-color: #ececec;
}

.pr-documents-inner-container {
  padding: 1.75rem 0;
}

.pr-documents-heading {
  font-size: 24px;
  font-weight: 600;
}

.pr-documents-sub-heading {
  font-size: 20px;
}

.pr-documents-flex-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.pr-documents-pointer {
  display: flex;
  align-items: baseline;
}

.pr-documents-pointer .MuiSvgIcon-root {
  font-size: 18px;
  color: green;
}

.pr-documents-pointer p {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
}

.pr-documents-right-container img {
  width: 300px;
}

@media screen and (max-width: 769px){
  .pr-documents-flex-container {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .pr-documents-heading {
    font-size: 22px;
  }

  .pr-documents-sub-heading {
    font-size: 18px;
  }

  .pr-documents-pointer p,
  .pr-documents-pointer .MuiSvgIcon-root {
    font-size: 16px;
  }
}
