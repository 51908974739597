.pr-eligibility-outer-container {
  margin-bottom: 5%;
  margin-top: 2%;
}

.pr-eligibility-heading {
  font-size: 24px;
  font-weight: 600;
}

.pr-eligibility-flex-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.pr-eligibility-pointer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.75rem;
}

.pr-eligibility-pointer .MuiSvgIcon-root {
  color: green;
}

.pr-eligibility-pointer p {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}

.pr-eligibility-image img {
  width: 300px;
}

.eligibilty-description{
  font-size: 20px;
}

@media screen and (max-width: 769px) {
  .pr-eligibility-flex-container {
    flex-direction: column;
  }

  .pr-eligibility-pointer p {
    text-align: left;
    font-size: 16px;
  }

  .pr-eligibility-pointer .MuiSvgIcon-root,
  .pr-eligibility-heading {
    font-size: 22px;
  }
}
