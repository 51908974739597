.about-banner-outer-containers {
    position: relative;
}

.about-banner-outer-containers img {
    width: 100%;
}

.about-heading {
    position: absolute;
    top: 50%;
    font-size: 45px;
    font-weight: 700;
    margin: 0 15%;
}

@media screen and (max-width:768px) {
    .about-heading {
        font-size: 32px;
    }
}