.contact-info-inner-container {
  text-align: left;
}

.contact-info-heading,
.contact-info-subheading {
  font-size: 24px;
  font-weight: 700;
}

.contact-info-description {
  font-size: 20px;
}

@media screen and (max-width: 769px) {
  .contact-info-heading,
  .contact-info-subheading {
    font-size: 20px;
  }

  .contact-info-description {
    font-size: 18px;
  }
}
