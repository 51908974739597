.canada-latest-banner-outer-container {
  background-color: #2c82c1;
}

.canada-latest-banner-inner-container {
  display: flex;
  align-items: start;
  justify-content: space-around;
}

.change-back{
  background-color: #00a9ce !important;
}

@media screen and (max-width: 769px) {
  .canada-latest-banner-inner-container {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }

  .latest-img-container img {
    width: 100%;
  }
}
