.enquiry-right-inner-container .MuiFormControl-root {
  width: 100%;
}

.enquiry-right-inner-container .MuiFormControl-root .MuiInputBase-root {
  border-radius: 7px;
}

.enquiry-right-inner-container .MuiFormControl-root .MuiInputBase-root::before {
  border-bottom: 0px !important;
}

.enquiry-right-inner-container
  .css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #d42d2d !important;
}

.enquiry-right-side-outer-container {
  width: 100%;
}

.input-field-space {
  margin: 1em 0;
}

.submit-form-btn {
  background-color: #d42d2d;
  width: 100%;
  outline: none;
  border: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 1rem 0;
  border-radius: 8px;
  cursor: pointer;
}

.MuiFormHelperText-root {
  position: absolute;
  padding-top: 50px;
}

.thank-you-inner-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thank-you-inner-section .MuiSvgIcon-root {
  font-size: 32px;
  color: #00d566;
}

.thankyou-first-heading {
  margin-bottom: 2px;
  font-size: 22px;
  font-weight: 600;
  color: #000;
}

.thankyou-second-heading {
  margin-top: 0;
  font-size: 22px;
  font-weight: 600;
  color: #d42d2d;
}
