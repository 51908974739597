@font-face {
    font-family: 'basic-sans';
    src: url('../basicsanssf.ttf') format('ttf'),
        url('../basicsanssfbold.ttf') format('ttf'),
        url('../basicsanssfitalic.ttf') format('ttf'),
        /* url('../Roboto-Regular.ttf') format('ttf'); */


}

html,
body,
button,
a,
p,
span,
button,
div {
    font-family: "basic-sans", sans-serif
}

a {
    text-decoration: none;
}