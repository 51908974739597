.tourist-visa-require-heading {
  font-size: 24px;
}

.tourist-visa-require-subheading {
  font-size: 20px;
}

.tourist-visa-require-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.tourist-requirement-content p{
  font-size: 20px;
  text-align: left;
}
