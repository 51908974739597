.contact-banner-outer-container {
  background-color: #d42d2d;
}

.contact-inner-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 4rem;
}

.contact-banner-heading {
  font-size: 42px;
  color: #fff;
}

@media screen and (max-width: 769px) {
  .contact-banner-heading {
    font-size: 32px;
  }

  .contact-inner-banner {
    flex-direction: column;
    padding-top: 0;
  }

  .contact-banner-img {
    width: 100%;
  }
}
