.offering-outer-container {
    margin-top: 7%;
}

.offerings-heading {
    font-size: 24px;
    font-weight: 700;
}

.offerings-inner-container {
    display: grid;
    grid-template: auto/1fr 1fr;
    gap: 8rem;
    row-gap: 2rem;
    margin: 0 10%;
}

.off-flex-containe {
    display: flex;
    flex-direction: column;

}

.offerings-img-container {
    background-color: #D42D2D;
    border-radius: 5px;
    padding: 0;
}

.offerings-img-container img {
    width: 100%;
}

.off-sub-heading,
.off-description {
    text-align: left;
    font-size: 16px;
}

.off-sub-heading {
    margin: 5px;
    margin-top: 5%;
}

.off-description {
    margin-top: 0;
    text-align: justify;
}


@media screen and (max-width:768px) {
    .offerings-inner-container {
        grid-template: auto/1fr;
    }

    .offerings-heading {
        font-size: 20px;
    }

    .off-description {
        font-size: 14px;
    }
}

@media screen and (max-width:468px) {
    .offerings-img-container img {
        width: 280px;
    }
}