.prbenefits-outer-container{
margin-bottom: 3%;
}

.prbenefits-heading {
  font-size: 24px;
  font-weight: 700;
}

.pr-benfits-box-container {
  display: grid;
  grid-template: auto/1fr 1fr;
  gap: 2rem;
}

.pr-benefits-box {
  background-color: #D42D2D;
  border-radius: 5px;
}

.pr-benefits-box p {
  text-align: left;
  font-size: 18px;
  margin: 0;
  padding: 1rem 2rem;
  color: #fff;
}

.benefits-sub-heading{
  text-align: justify;
  font-size: 20px;
}

@media screen and (max-width: 769px) {
  .pr-benfits-box-container {
    grid-template: auto/1fr;
  }

  .pr-benefits-box p {
    font-size: 16px;
  }
}
