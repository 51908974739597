/* MyTable.css */
.my-table {
  border-collapse: collapse;
  width: 100%;
}

.my-table th,
.my-table td {
  border: 1px solid #ea4142;
  padding: 8px;
  text-align: center;
}

.my-table th {
  background-color: #fff;
  font-size: 24px;
}

.my-table th,
.my-table td {
  min-width: 100px; /* Set a minimum width for the cells */
}

.my-table td {
  font-size: 18px;
}

/* Media query for responsive design */
@media screen and (max-width: 768px) {
  .my-table,
  .my-table td {
    font-size: 16px; /* Decrease font size for smaller screens */
  }

  .my-table th {
    font-size: 20px;
  }

  .my-table th,
  .my-table td {
    padding: 6px; /* Decrease cell padding for smaller screens */
  }
}
