.immigration-outer-container {
    margin-top: 7%;
}

.immigration-heading {
    font-size: 24px;
    font-weight: 700;
}

.immigration-content {
    text-align: left;
    font-size: 18px;
}

.types-immigration-container {
    margin: 0 12%;
    margin-bottom: 7%;
}

.types-immigration-inner-container {
    display: grid;
    grid-template: auto/ 1fr 1fr;
    gap: 2rem;
}

.immigration-types-box {
    border-radius: 10px;
    background: #D42D2D;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.immigration-types-box img {
    width: 100px;
}

.types-immigration-content {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}

.types-immigration-description-outer-container {
    width: 80%;
    /* Customize the width of the carousel container */
    margin: 0 auto;
    /* Center the carousel horizontally */
}

/* Slide container */
.types-description-inner-container {
    border-radius: 10px;
    background: #F6F5F5;
    padding: 20px;
}

/* Heading style */
.description-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 0;
}

/* Description content style */
.description-content {
    font-size: 16px;
    text-align: left;
}


.carousel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.carousel-container {
    display: flex;
    justify-content: center;
    position: relative;
}

.carousel-item {
    flex: 0 0 100%;
    display: none;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: opacity 0.3s;
    border-radius: 40px;
    background: #F5F7FA;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 2rem;
    padding-top: 1.5rem;
}

.carousel-item.active {
    display: block;
}

.carousel-dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #aaa;
    margin: 0 5px;
}

.dot.active {
    background-color: #D42D2D;
}

.immigrations-img-container {
    margin-top: 5%;
}

.immigrations-img-container img {
    width: 600px;
}

@media screen and (max-width:768px) {
    .types-immigration-inner-container {
        grid-template: auto/1fr;
    }

    .immigrations-img-container img {
        width: 460px;
    }


}

@media screen and (max-width:468px) {
    .immigrations-img-container img {
        width: 370px;
    }

    .carousel-item {
        width: 200px;
    }
}