.video-section-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.video-section-outer-container{
    margin-top: 2%;
}

.video-container {
  width: 100%;
  height: 100%;
}

/* .video-form-right-container {
  width: 85%;
} */

.video-second-container {
  margin-top: 4%;
}

.video-second-inner-container {
  display: grid;
  grid-template: auto/1fr 1fr;
  gap: 2rem;
}

.video-caption {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.video-mobile {
  display: none;
}

.video-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-banner-outer-container {
  background-color: #d42d2d;
}

.video-banner-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.video-banner-inner-container h2 {
  font-size: 55px;
  font-weight: 700;
  color: #fff;
}

@media screen and (max-width: 769px) {
  .video-second-inner-container {
    grid-template: auto/1fr;
  }

  .video-caption {
    font-size: 16px;
  }

  .video-form-right-container {
    display: none;
  }

  .video-mobile {
    display: block;
    width: 100%;
  }

  .video-banner-inner-container {
    flex-direction: column;
  }

  .video-banner-inner-container h2 {
    font-size: 24px;
  }

  .video-banner-inner-container img{
    width: 100%;
  }
}
