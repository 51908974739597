.arrange_employement_inner_container h2 {
  font-size: 24px;
  font-weight: 700;
}

.arrange_employement_content {
  font-size: 18px;
  text-align: justify;
}

.pointers-box-flex-contaier {
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  gap: 2rem;
}

.pointer_heading {
  border-radius: 5px 5px 0 0;
  border: 0px solid #ea4142;
  background: #ea4142;
  padding: 5px;
}

.pointer_heading p {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin: 0;
}

.pointers_box_content {
  border-radius:5px;
  border: 1px solid #f00;
  background: var(--Color, #fff);
}

.pointers_box_content p {
  font-size: 18px;
}

.pointers_box_content p ul,
.pointers_box_content p {
  margin-top: 0;
}

.pointers_box_content p ul {
  text-align: left;
  padding: 25px;
}

.pointers_box_content p ul li {
  padding-bottom: 3px;
  font-size: 18px;
}

@media screen and (max-width: 769px) {
  .arrange_employement_inner_container h2 {
    font-size: 18px;
  }

  .arrange_employement_content,
  .pointers_box_content p ul li {
    font-size: 16px;
  }

  .pointer_heading p,
  .pointers_box_content p {
    font-size: 16px;
  }

  .pointers-box-flex-contaier {
    grid-template: 1fr/1fr;
  }
}
