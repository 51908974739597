body {
  margin: 0;
}

.container_wrapper {
  display: block;
  width: calc(min(1600px, 90vw));
  margin: auto;
}

.canada-page-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 2rem;
  padding-top: 2rem;
}

.canada-page-flex-left-container {
  flex: 50% 1;
  /* overflow: auto; */
}

.canada-page-right-container {
  flex: 20% 1;
  position: sticky;
  top: 110px;
}

.canada-page-right-container .abroad-form-outer-containet {
  width: unset;
}

.vertical-text,
.vertical-text-worker,
.vertical-text-b,
.vertical-text-exp {
  writing-mode: vertical-rl; /* or vertical-lr */
  text-orientation: mixed; /* This property is optional but helps with browser compatibility */
  white-space: nowrap; /* Prevents text from wrapping */
  transform: rotate(180deg);
  transform-origin: left bottom; /* Adjust the origin as needed */
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  top: 359px;
  position: absolute;
  float: left;
}

.vertical-text-exp {
  top: 180px;
}

.vertical-text-b {
  top: 287px;
}

.vertical-text-worker {
  top: 175px;
}

.link-to-page p {
  color: #2c82c1;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin-top: 0;
}

.latest-banner-lest-container h2 {
  font-size: 50px;
  color: #fff;
  margin-bottom: 2px;
  text-align: left;
}

.latest-banner-lest-container p {
  font-size: 35px;
  color: #fff;
  text-align: left;
  margin-top: 0;
  font-weight: 600;
}

.fedral-image-width {
  width: 670px;
}
.canadaian-banner {
  width: 300px;
}

.super-banner {
  width: 400px;
}

.fedral-work-back {
  width: 600px;
}
@media screen and (max-width: 769px) {
  .canada-page-flex-container {
    flex-direction: column;
    align-items: inherit;
    margin-bottom: 2%;
  }

  .canada-page-right-container {
    position: unset;
  }

  .canada-pages-little-image img {
    width: 100%;
  }

  .latest-banner-lest-container h2 {
    font-size: 25px;
  }

  .latest-banner-lest-container p {
    font-size: 18px;
    text-align: center;
  }

  .latest-banner-right-container img {
    width: 100%;
  }

  .page-image,
  .super-banner,
  .canadaian-banner,
  .fedral-work-back {
    width: 100%;
  }

  .link-to-page p {
    font-size: 18px;
  }

  .vertical-text {
    top: 240px;
  }

  .vertical-text-b {
    top: 250px;
  }
}
