.partner-banner-outer-container {
  background-color: #d42d2d;
}

.partner-banner-inner-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.partner-banner-heading {
  font-size: 50px;
  color: #fff;
}

@media screen and (max-width: 769px) {
  .partner-banner-inner-container {
    flex-direction: column;
  }

  .partner-banner-heading {
    font-size: 32px;
  }

  .partner-right-conatiner img {
    width: 100%;
  }
}
