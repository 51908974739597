.pr-banner-inner-container {
  display: flex;
  align-items: center;
  padding: 3rem 2rem 0 1rem;
  justify-content: space-between;
}

.pr-banner-heading {
  color: #d42d2d;
  font-size: 45px;
  font-weight: 700;
  text-align: left;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.pr-sub-heading {
  font-size: 40px;
}

.pr-banner-heading p {
  margin: 0;
}

.pr-banner-img img {
  /* width: 100px; */
  /* aspect-ratio: 1; */
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 769px) {
  .pr-banner-inner-container {
    flex-direction: column;
    padding-right: 1rem;
    padding-top: 1rem;
    gap: 7rem;
  }

  .pr-banner-img img {
    width: 680px;
  }

  .pr-banner-heading {
    text-align: center;
  }
}

@media screen and (max-width: 469px) {
  .pr-banner-img img {
    width: 370px;
  }

  .pr-sub-heading,
  .pr-banner-heading {
    font-size: 30px;
  }
}
