.footer-outer-container {
  background-color: #6C6C6C;
  margin-top: 5%;
}

.footer-inner-container {
  display: flex;
  justify-content: space-between;
}

.footer-content {
  display: grid;
  grid-template: auto/1fr 1fr 1fr;
}

.footer-div-content {
  color: #fff;
  font-size: 16px;
  text-align: start;
}

.footer-below-left-container a,
.footer-social-media .MuiSvgIcon-root {
  color: #fff;
}

.footer-below-container {
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
}

.footer-below-left-container,
.footer-below-right-container,
.footer-social-media {
  display: flex;
  align-items: center;
}

.footer-below-left-container {
  gap: 1rem;
}

.footer-social-media,
.footer-below-right-container {
  gap: 2rem;
}

@media screen and (max-width: 769px) {
  .footer-below-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-inner-container {
    flex-direction: column;
    align-items: start;
  }

  .footer-left-container img {
    width: 120px;
  }
}
