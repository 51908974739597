.home-banner-outer-container {
    display: flex;
    justify-content: space-between;
}

.banner-left-image img {
    left: 0;
    position: absolute;
}

.home-banner-right-container img {
    width: 480px;
}

.home-banner-heading {
    font-size: 45px;
    font-weight: 700;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 99;
    display: flex;
    flex-direction: column;
    padding-top: 8rem;
}

.home-banner-heading p {
    padding-left: 9rem;

}

.heading1 {
    margin: 0;
}

.heading2 {
    margin-top: 0;
    color: #D42D2D;
}

@media screen and (max-width:768px) {
    .home-banner-outer-container {
        flex-direction: column;
    }

    .home-banner-right-container img {
        width: 390px;
    }

    .home-banner-heading p {
        padding: 1rem;
        font-size: 36px;
    }

    .banner-left-image img {
        width: 200px;
    }

}

@media screen and (max-width:468px) {
    .home-banner-heading p {
        font-size: 32px;
    }
}