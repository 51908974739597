.enquiry-b2b-subHeading {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 35px;
  font-weight: 700;
}

.enquiry-service-heading {
  font-size: 30px;
  font-weight: 600;
}

.enquiry-service-content {
  font-size: 20px;
  text-align: left;
}

.enquiry-service-pointer {
  display: grid;
  grid-template: auto/1fr 1fr;
  gap: 2rem;
}

.enquiry-service-pointer-outer-container {
  background-color: #d42d2d;
  color: #fff;
  text-align: justify;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.enquiry-service-pointer-heading {
  font-size: 18px;
  font-weight: 600;
}

.enquiry-service-pointer-content {
  font-size: 18px;
}

.enquiry-partner-content {
  text-align: left;
  font-size: 20px;
}

.enquiry-partner-flex-container {
  display: flex;
  /* align-items: center; */
}

.enquiry-partner-right-container img {
  width: 520px;
}

.partner-contact-us {
  background-color: #d42d2d;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 2rem;
  gap: 1rem;
}

.partner-contact-heading,
.partner-divider {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 45px;
  font-weight: 600;
}

.partner-contact-menu .MuiSvgIcon-root,
.partner-contact-menu p {
  font-size: 25px;
  margin: 0;
}

.partner-divider {
  font-size: 100px;
  font-weight: 400;
  margin: 0;
}

.partner-contact-sontainer {
  display: flex;
  flex-direction: column;
}

.partner-contact-menu {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.partner-email {
  margin-top: 2rem;
}

.partner-contact-us-outer-contaier {
  margin-bottom: 2%;
}

@media screen and (max-width: 769px) {
  .enquiry-b2b-subHeading {
    font-size: 22px;
  }
  .enquiry-service-heading {
    font-size: 22px;
  }

  .enquiry-service-pointer {
    grid-template: auto/1fr;
  }

  .enquiry-service-pointer-content {
    font-size: 16px;
  }

  .enquiry-partner-flex-container,
  .partner-banner-inner-container {
    flex-direction: column;
  }

  .enquiry-partner-content,
  .enquiry-service-content {
    font-size: 18px;
  }

  .enquiry-partner-right-container img {
    width: 100%;
  }

  .partner-contact-us {
    flex-direction: column;
  }

  .partner-divider {
    display: none;
  }

  .partner-contact-heading {
    font-size: 35px;
    margin-bottom: 0;
  }

  .partner-contact-menu p {
    font-size: 18px;
  }
}

@media screen and (max-width: 469px) {
  .partner-contact-menu p {
    font-size: 16px;
  }
}
